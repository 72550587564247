<template>
    <div v-if="flag == 1">
        <!-- 基本信息 -->
        <div class="block">
            <div class="title">基本信息</div>
            <div class="cell">
                <div class="left">入职工厂<span>*</span></div>
                <div class="right"><input type="text" readonly v-model="details.factory_name" class="disabled" /></div>
            </div>
            <div class="cell">
                <div class="left">入职职位<span>*</span></div>
                <div class="right"><input type="text" v-model="details.job" placeholder="请输入" maxlength="10" /></div>
            </div>
            <div class="cell">
                <div class="left">手机号码<span>*</span></div>
                <div class="right"><input type="text" v-model="details.mobile" @input="sms_verify = false" placeholder="请输入" maxlength="11" /></div>
            </div>
            <div class="cell" @click="showPicker('members')" v-if="array.members && array.members.length">
                <div class="left">招聘经纪人<span>*</span></div>
                <div class="right"><input type="text" readonly v-model="details.members" placeholder="请选择" /></div>
            </div>
            <div class="cell" v-if="interview_list.length==0">
                <div class="left">供应商</div>
                <div class="right"><input type="text" v-model="details.supplier_name" placeholder="请输入" @input="details.supplier_name = details.supplier_name.replace(/[^\w\u4E00-\u9FA5]/g, '')" maxlength="10" /></div>
            </div>
        </div>
        <!-- 身份证信息 -->
        <div class="block">
            <div class="title">
                <div>身份证信息</div>
                <van-uploader :after-read="getimgidcard">
                    <div class="iconfont icon-xiangji"></div>
                </van-uploader>
            </div>
            <div class="cell">
                <div class="left">姓名<span>*</span></div>
                <div class="right pd-r"><input type="text" :readonly="isDisabled" :class="{disabled: isDisabled}" v-model="details.name" @input="details.name = details.name.replace(/[^\w\u4E00-\u9FA5]/g, '')" maxlength="10" placeholder="请输入" /></div>
            </div>
            <div class="cell">
                <div class="left">身份证号<span>*</span></div>
                <div class="right pd-r"><input type="text" :readonly="isDisabled" :class="{disabled: isDisabled}" v-model="details.card_num" placeholder="请输入" @input="details.card_num = details.card_num.replace(/[^0-9Xx]/g, '')" maxlength="18" /></div>
            </div>
            <!-- <div class="cell" @click="showPicker('sex')">
                <div class="left">性别<span>*</span></div>
                <div class="right"><input type="text" readonly v-model="details.sex" placeholder="请选择" /></div>
                <van-icon name="arrow" />
            </div> -->
            <div class="cell" @click="showPicker('nation')">
                <div class="left">民族</div>
                <div class="right"><input type="text" readonly v-model="details.nation" placeholder="请选择" /></div>
                <van-icon name="arrow" />
            </div>
            <!-- <div class="cell">
                <div class="left">地址</div>
                <div class="right pd-r"><input type="text" v-model="details.native_place" placeholder="请输入" /></div>
            </div> -->
            <!-- <div class="cell" @click="showPopup('showBirthday')">
                <div class="left">出生日期<span>*</span></div>
                <div class="right"><input type="text" readonly v-model="details.birthyear" placeholder="请选择" /></div>
                <van-icon name="arrow" />
            </div> -->

            <!-- <div class="cell" @click="showPopup('showIssue')">
                <div class="left">签发日期</div>
                <div class="right"><input type="text" readonly v-model="details.issue_time" placeholder="请选择" /></div>
                <van-icon name="arrow" />
            </div>
            <div class="cell" @click="showPopup('showInvalid')">
                <div class="left">失效日期</div>
                <div class="right"><input type="text" readonly v-model="details.invalid_time" placeholder="请选择" /></div>
                <van-icon name="arrow" />
            </div>
            <div class="cell" @click="showPicker('education')">
                <div class="left">学历</div>
                <div class="right"><input type="text" v-model="details.education" placeholder="请选择" readonly /></div>
                <van-icon name="arrow" />
            </div>
            <div class="cell" @click="showPicker('experience')">
                <div class="left">工作经验</div>
                <div class="right"><input type="text" v-model="details.experience" placeholder="请选择" readonly /></div>
                <van-icon name="arrow" />
            </div> -->
        </div>
        <!-- 银行卡信息 -->
        <div class="block">
            <div class="title">
                <div>银行卡信息</div>
                <van-uploader :after-read="getBank">
                    <div class="iconfont icon-xiangji"></div>
                </van-uploader>
            </div>
            <div class="cell">
                <div class="left">银行名称<span style="color:red" v-if="is_branchbank">*</span></div>
                <div class="right pd-r2"><input type="text" v-model="details.bank_name" placeholder="请输入" /></div>
            </div>
            <div class="cell">
                <div class="left">银行卡号<span style="color:red" v-if="is_branchbank">*</span></div>
                <div class="right pd-r2"><input type="text" v-model="details.bank_card_num" @input="details.bank_card_num = details.bank_card_num.replace(/[^0-9]/g, '')" placeholder="请输入" /></div>
            </div>
            <div class="cell">
                <div class="left">开户行<span style="color:red" v-if="is_branchbank">*</span></div>
                <div class="right pd-r2"><input type="text" v-model="details.branch_bank" placeholder="请输入" /></div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="footer-bottom" v-if="is_show"></div>
        <div class="news_footer" v-if="is_show">
            <div class="news_footer_on" @click="submit" :class="is_click ? 'd8' : ''">提交</div>
        </div>

        <van-popup v-model="show" round position="bottom">
            <van-picker show-toolbar :columns="columns" :value-key="type+'_desc'" @cancel="show = false" @confirm="onConfirm" />
        </van-popup>
        <van-popup v-model="showBirthday" round position="bottom">
            <van-datetime-picker v-model="birthday" type="date" title="选择出生日期" :min-date="minBirthday" :max-date="maxBirthday" :formatter="formatter" @cancel="hideTimeSelect" @confirm="selectTime($event, 'birthday')" />
        </van-popup>
        <van-popup v-model="showIssue" round position="bottom">
            <van-datetime-picker v-model="issue_time" type="date" title="选择签发日期" :min-date="minDateIssue" :max-date="maxDateIssue" :formatter="formatter" @cancel="hideTimeSelect" @confirm="selectTime($event, 'issue_time')" />
        </van-popup>
        <van-popup v-model="showInvalid" round position="bottom">
            <van-datetime-picker v-model="invalid_time" type="date" title="选择失效日期" :min-date="minDateInvalid" :max-date="maxDateInvalid" :formatter="formatter" @cancel="hideTimeSelect" @confirm="selectTime($event, 'invalid_time')" />
        </van-popup>

        <van-popup v-model="show_getCode" class="popup">
            <h3>获取验证码</h3>
            <p>为了确保您能收到入职成功短信，请填写6位数字短信验证码!</p>
            <div class="username sms_box">
                <div class="left">验证码</div>
                <div class="right">
                    <van-field v-model="details.code" maxlength="6" type="digit" class="input" placeholder="请输入验证码">
                        <template #button>
                            <van-button class="sms_btn" size="small" @click="toverify" type="info">{{time ? time+'s' : '获取验证码' }}</van-button>
                        </template>
                    </van-field>
                </div>
            </div>
            <div class="bottom">
                <div @click="show_getCode = false">取消</div>
                <div @click="verification">确认</div>
            </div>
        </van-popup>
        <!-- 弹窗 -->
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">绑定手机号码</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">该手机号码已绑定其他微信，确认换绑到本微信？</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="my_lizhi = false">取消绑定</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认换绑</div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="flag == 2"></div>
    <div v-else class="err">
        <van-icon name="clear" color="#FC6E53" size="40px" />
        <div class="err_text">{{ error }}</div>
        <div v-if="flag == -1" class="footer-bottom">
            <div @click="closeWindow">取消</div>
            <div @click="submit" v-if="activation">确认</div>
            <div @click="flag = 1" v-else>继续申请</div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import wx from "weixin-js-sdk";
import * as imageConversion from 'image-conversion'
export default {
    data() {
        return {
            details: {},
            array: {
                members: [],
                sex: ['男', '女'],
                nation: [
                    '汉',
                    '壮',
                    '满',
                    '回',
                    '苗',
                    '维吾尔',
                    '土家',
                    '彝',
                    '蒙古',
                    '藏',
                    '布依',
                    '侗',
                    '瑶',
                    '朝鲜',
                    '白',
                    '哈尼',
                    '哈萨克',
                    '黎',
                    '傣',
                    '畲',
                    '僳僳',
                    '仡佬',
                    '东乡',
                    '拉祜',
                    '水',
                    '佤',
                    '纳西',
                    '羌',
                    '土',
                    '仫佬',
                    '锡伯',
                    '柯尔克孜',
                    '达翰尔',
                    '景颇',
                    '毛南',
                    '撒拉',
                    '布朗',
                    '塔吉克',
                    '阿昌',
                    '普米',
                    '鄂温克',
                    '怒',
                    '京',
                    '基诺',
                    '德昂',
                    '保安',
                    '俄罗斯',
                    '裕固',
                    '乌孜别克',
                    '门巴',
                    '鄂伦春',
                    '独龙',
                    '塔塔尔',
                    '赫哲',
                    '高山',
                    '珞巴'
                ],
            }, // picker
            docmHeight: window.innerHeight, //默认屏幕高度
            showHeight: window.innerHeight, //实时屏幕高度
            is_show: true, // 弹起输入框要把底部按钮屏蔽掉
            show_getCode: false, // 是否展示手机号码弹框
            sms_verify: true, // 手机号码已验证
            is_branchbank: false, // 开户行是否必填
            is_click: false,// 点击了提交，要置灰，等返回结果后恢复
            bd_token: '', // 百度api的token
            flag: 2,// 是否已经申请过入职了   默认是2,：空白页面，1：正常展示页面，-1跟0是异常页面
            activation: 0, // 待激活员工确认换厂
            isDisabled: false, // 是否禁止输入
            error: '',
            my_lizhi: false, // 手机号码已被绑定
            interview_list: [],
            // popup内容=======================
            time: 0,
            columns: [],
            type: '',
            show: false,
            showInvalid: false,
            showIssue: false,
            showBirthday: false,
            minBirthday: new Date(1950, 0, 1),
            maxBirthday: new Date(),
            minDateIssue: new Date(2010, 0, 1),
            minDateInvalid: new Date(2010, 0, 1),
            maxDateIssue: new Date(2099, 10, 1),
            maxDateInvalid: new Date(2099, 10, 1),
            issue_time: new Date(),
            invalid_time: new Date(),
            birthday: new Date(),
            // ================================================
        }
    },
    watch: {
        showHeight: function (newValue) {
            if (this.isAndroidOrIOS() == 'android') {
                if (this.docmHeight > newValue) {
                    this.is_show = false
                } else {
                    this.is_show = true
                }
            }
        },
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.showHeight = window.innerHeight;
            })();
        };
        this.$store.commit("getWxSDK");
        this.$store.commit('hideTabbar');
        this.getcategory()
        this.ISBranchBank()
        this.baiduApi()
        this.getDetails()
    },
    methods: {
        closeWindow() {
            wx.closeWindow();
        },
        isAndroidOrIOS() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                return 'android';
            }
            if (isiOS) {
                return 'ios';
            }
            return false;
        },
        submit() {
            if (this.is_click) return
            if (this.isnull()) {
                this.is_click = true
                var data = {
                    fac_member_id: this.getQuery('member_id'),
                    bus_member_id: this.details.members_id || this.getQuery('member_id'),
                    factory_id: this.getQuery('factory_id'),
                    introducer_id: this.details.introducer_id || 0,
                    card_num: this.details.card_num.toUpperCase(),
                    // card_url: this.$store.state.idcardURL.toString(),
                    real_name: this.details.name,
                    nation: this.details.nation || '',
                    Issue_time: this.details.issue_time ? Math.round(new Date(this.details.issue_time.replace(/-/g, '/')).getTime() / 1000).toString() : '',
                    invalid_time: this.details.invalid_time ? Math.round(new Date(this.details.invalid_time.replace(/-/g, '/')).getTime() / 1000).toString() : '',
                    bank_card_num: this.details.bank_card_num || '',
                    bank_name: this.details.bank_name || '',
                    branch_bank: this.details.branch_bank || '',
                    mobile: this.details.mobile,
                    sex: this.details.sex == '男' ? 1 : 2,
                    education: this.details.education_id || 0,
                    experience: this.details.experience_id || 0,
                    job: this.details.job,
                    native_place: this.details.native_place,
                    jobseeker_id: this.details.jobseeker_id || 0,
                    supplier_name: this.details.supplier_name || '', // 供应商
                }
                this.Request('client/employ/apply', 'post', data).then(res => {
                    this.is_click = false
                    if (res.status == 1 || res.status == 3) { // 需要换厂, 需要提示已在职
                        this.activation = 1
                        this.getDetails()
                    } else if (res.status == 2) { // 入职成功
                        this.$router.replace({ path: '/subSuccess', query: { type: 'entry_success' } });
                    } else if (res.status == 0) {
                        this.$router.replace({ path: '/subSuccess', query: { type: 'entry' } });
                    } else {
                        Toast(res.msg);
                    }
                });
            }
        },
        isnull() {
            if (this.sms_verify) {
                if (!this.details.job) {
                    Toast('请输入入职职位')
                    return
                }
                if (!this.details.mobile) {
                    Toast('请输入手机号码')
                    return
                }
                if (!this.details.members && this.array.members && this.array.members.length) {
                    Toast('请选择招聘经纪人')
                    return
                }
                if (this.details.supplier_name && this.details.supplier_name.length < 2) {
                    Toast('供应商长度不能小于2个汉字')
                    return
                }
                if (!this.details.name) {
                    Toast('请输入真实姓名')
                    return
                }
                // if (!this.details.sex) {
                //     Toast('请选择性别')
                //     return
                // }
                // if (!this.details.nation) {
                //     Toast('请选择民族')
                //     return
                // }
                if (!this.details.card_num) {
                    Toast('请输入身份证号')
                    return
                }
                // if (!this.details.issue_time) {
                //     Toast('请选择签发日期')
                //     return
                // }
                // if (!this.details.invalid_time) {
                //     Toast('请选择失效日期')
                //     return
                // }
                if (this.is_branchbank) {
                    if (!this.details.bank_name) {
                        Toast('请输入银行名称')
                        return
                    }
                    if (!this.details.bank_card_num) {
                        Toast('请输入银行卡号')
                        return
                    }
                    if (!this.details.branch_bank) {
                        Toast('请输入开户行')
                        return
                    }
                } else {
                    if (!this.details.bank_name && this.details.bank_card_num) {
                        Toast('请输入银行名称')
                        return
                    }
                    if (!this.details.bank_card_num && this.details.bank_name) {
                        Toast('请输入银行卡号')
                        return
                    }
                }
                return true
            } else {
                this.show_getCode = true
                return
            }
        },
        // 身份证识别
        getimgidcard(e) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            imageConversion.compressAccurately(e.file, 100).then(res => {
                let img = new window.File([res], 'cs.png', { type: 'image/png' },);
                this.getBase64(img).then(res => {
                    var image = res
                    let params = new FormData()
                    params.append('image', res)
                    params.append('id_card_side', 'front')
                    params.append('access_token', this.bd_token)
                    this.axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
                    this.axios.post('https://aip.baidubce.com/rest/2.0/ocr/v1/idcard', params).then(res => {
                        if (res.data.idcard_number_type < 0) {
                            Toast.clear()
                            Toast('请上传正确的身份证图片')
                            return
                        }
                        var data = {}
                        if (res.data.words_result['出生']) {
                            this.Request('card/img/upload', 'post', { img: image.split(',')[1], img_type: 'id_card' }).then(res2 => {
                                this.$store.commit('idcardURL_add', { index: 0, item: res2.data[0] })
                            })
                            if (!!res.data.words_result['姓名'].words == false || !!res.data.words_result['性别'].words == false || !!res.data.words_result['民族'].words == false || !!res.data.words_result['住址'].words == false || !!res.data.words_result['出生'].words == false || !!res.data.words_result['公民身份号码'].words == false) {
                                Toast.clear()
                                Toast('请上传正确的身份证人像面图片')
                                return
                            } else {
                                Toast.clear()
                                Toast.success('上传成功')
                            }
                            var birthday = res.data.words_result['出生'].words

                            data = {
                                'sex': res.data.words_result['性别'].words,
                                'nation': res.data.words_result['民族'].words,
                                'native_place': res.data.words_result['住址'].words,
                                'birthyear': birthday.substring(0, 4),
                                is_card_true: false
                            }
                            if (!this.isDisabled) { // 已在职的求职者，姓名跟身份证号码不能更改
                                data.card_num = res.data.words_result['公民身份号码'].words
                                data.name = res.data.words_result['姓名'].words
                            }
                        } else {
                            var issue_time = res.data.words_result['签发日期'].words
                            var invalid_time = res.data.words_result['失效日期'].words
                            if (!!issue_time == false || !!invalid_time == false) {
                                Toast('身份证国徽面识别失败，请重新上传')
                                return
                            }
                            data = {
                                issue_time: issue_time.substring(0, 4) + '-' + issue_time.substring(4, 6) + '-' + issue_time.substring(6, 8),
                                invalid_time: invalid_time.substring(0, 4) + '-' + invalid_time.substring(4, 6) + '-' + invalid_time.substring(6, 8),
                            }

                        }
                        this.details = { ...this.details, ...data }
                    })
                    Toast.clear()
                })
            });
        },
        // 银行卡识别
        getBank(e) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            this.getBase64(e.file).then(res => {
                let params = new FormData();
                params.append('image', res);
                params.append('access_token', this.bd_token);
                this.axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                this.axios
                    .post('https://aip.baidubce.com/rest/2.0/ocr/v1/bankcard', params)
                    .then(res => {
                        this.details.bank_name = res.data.result.bank_name
                        this.details.bank_card_num = res.data.result.bank_card_number.replace(/\D/g, '')
                        this.details.branch_bank = ''
                        this.details = { ...this.details }
                        Toast.clear()
                        if (res.data.result.bank_card_type == 0 || (!this.details.bank_name && !this.details.bank_card_num)) {
                            Toast('银行卡信息识别失败')
                        }
                    })
                    .catch(() => {
                        Toast.clear()
                        Toast('银行卡信息识别失败')
                        this.details.branch_bank = ''
                        this.details.bank_name = ''
                        this.details.bank_card_num = ''
                    });
                Toast.clear();
            });
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(imgResult);
                };
            });
        },
        // 获取基本信息
        getDetails() {
            var data = {
                member_id: this.getQuery('member_id'),
                factory_id: this.getQuery('factory_id')
            }
            this.Request('client/employ/apply', 'get', data).then(res => {
                if (res.status == 0) {
                    if (res.data.is_pending_employ_apply) { // 扫的还是当前的入职码
                        this.$router.replace({ path: '/subSuccess', query: { type: 'entry' } });
                    } else {
                        this.flag = 1;
                        this.isDisabled = false
                        // 已在职
                        if (Object.keys(res.data.incumbent_info).length) {
                            if (res.data.incumbent_info.factory_id === Number(this.getQuery('factory_id'))) { // 扫码的是同一个工厂
                                this.flag = 0;
                                this.error = "已在“" + res.data.incumbent_info.factory_name + "”在职";
                                return
                            } else {
                                this.flag = -1;
                                this.isDisabled = true
                                this.error = "已在“" + res.data.incumbent_info.factory_name + "”在职，该入职申请通过后，您将在原来的工厂离职，在新的工厂入职，是否继续申请入职？";
                            }
                        }
                        this.details = { ...res.data.employ_info }
                        if (this.details.birthyear && this.details.birthyear.birthdate_full) this.details.birthyear = this.details.birthyear.birthdate
                        if (this.details.Issue_time) this.details.issue_time = this.timestampToTime(this.details.Issue_time)
                        if (this.details.invalid_time) { this.details.invalid_time = this.timestampToTime(this.details.invalid_time) } else {
                            this.details.invalid_time = ''
                        }
                        if (this.details.mobile) {
                            this.show_getCode = false;
                            this.sms_verify = true; // 已认证
                        }
                        if (res.data.interview_list.length == 1) {
                            this.details.members_id = res.data.interview_list[0].member_id
                            this.details.members = res.data.interview_list[0].member_name
                            this.details.jobseeker_id = res.data.interview_list[0].jobseeker_id
                            this.details.introducer_id = res.data.interview_list[0].introducer_id
                        }
                        res.data.interview_list.forEach(item => {
                            this.array.members.push({ members_desc: item.member_name, members_id: item.member_id, jobseeker_id: item.jobseeker_id, introducer_id: item.introducer_id });
                        });
                        this.interview_list = res.data.interview_list

                    }
                } else {
                    this.flag = 0;
                    this.error = res.msg;
                }
            })
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate() + ' ';
            return Y + M + D;
        },
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split('?');
            var vars = lengths[lengths.length - 1].split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        verification() {
            if (!this.details.code) {
                Toast('请输入验证码');
                return;
            }
            var data = {
                jobseeker_mobile: this.details.mobile,
                mobile_verify_code: this.details.code,
                option: 'verify'
            };
            this.Request('client/mine/info', 'post', data).then(res => {
                if (res.status == -10) { // 换绑手机号码
                    this.my_lizhi = true
                    this.show_getCode = false;
                } else if (res.status == 0) {
                    this.show_getCode = false;
                    this.sms_verify = true
                } else {
                    Toast(res.msg);
                }
            });
        },
        verificationMobile() {
            if (!this.details.mobile) {
                Toast('请输入手机号码')
                return
            }
            if (!(/^[1]([3-9])[0-9]{9}$/.test(this.details.mobile))) {
                Toast('请输入正确的手机号码');
                return false;
            }
            return true
        },
        // 获取验证码
        toverify() {
            if (this.time) { return }
            if (this.verificationMobile()) {
                this.time = 60
                var that = this
                var timer = setInterval(() => {
                    if (that.time == 0) {
                        clearInterval(timer)
                    } else {
                        that.time--
                    }
                }, 1000);
                var data = {
                    mobile: this.details.mobile,
                    option: 'verify'
                }
                this.Request('client/sms', 'get', data).then(res => {
                    if (res.status == 0) {
                        Toast.success('发送成功')
                    }
                })
            }

        },
        showPopup(type) {
            this[type] = true;
        },
        selectTime(e, type) {
            var year = e.getFullYear();
            var month = e.getMonth() + 1 < 10 ? '0' + (e.getMonth() + 1) : e.getMonth() + 1;
            var day = e.getDate() < 10 ? '0' + e.getDate() : e.getDate();
            // this.list[type] = e.getFullYear() + '-' + (e.getMonth() + 1) + '-' + e.getDate();
            this.details[type] = year + '-' + month + '-' + day;
            this.hideTimeSelect()
        },
        showPicker(type) {
            this.columns = this.array[type];
            this.type = type;
            if (this.columns.length) {
                this.show = true;
            } else {
                if (type == 'members') {
                    Toast('没有招聘经纪人');
                } else {
                    Toast('没有推荐人');
                }
            }
        },
        hideTimeSelect() {
            this.showIssue = false;
            this.showInvalid = false;
            this.showBirthday = false;
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type == 'day') {
                return `${val}日`;
            }
            return val;
        },
        cancle_ok() {
            this.Request('mobile_bind', 'put', { new_mobile: this.details.mobile }).then(res => {
                if (res.status == 0) {
                    this.my_lizhi = false;
                    this.show_getCode = false;
                    this.sms_verify = true
                }
            })
        },
        onConfirm(e) {
            if (this.type == 'education' || this.type == 'experience' || this.type == 'members') {
                this.details[this.type] = e[this.type + '_desc']
                this.details[this.type + '_id'] = e[this.type + '_id']
                if (this.type == 'members') {
                    this.details.introducer_id = e.introducer_id
                    this.details.jobseeker_id = e.jobseeker_id
                }
            } else {
                this.details[this.type] = e
            }
            this.show = false;
        },
        // 文字识别
        baiduApi() {
            this.Request('ai_baidu/access_token', 'get').then(res => {
                this.bd_token = res.data.access_token;
            });
        },
        // 获取数组
        getcategory() {
            this.Request('category', 'get', { type: 'all' }).then(res => {
                this.array = { ...this.array, ...res.data }
            });
        },
        // 开户行是否必填
        ISBranchBank() {
            this.Request('team/config', 'get', {
                team_id: this.getQuery('team_id') || localStorage.getItem('team_id')
            }).then(res => {
                if (res.status == 0 && res.data.branch_bank_required == 1) {
                    this.is_branchbank = true
                }
            })
        },
        back() {
            this.$router.replace('/home')
        },
    },

}
</script>
<style scoped>
.footer-bottom {
    display: flex;
    justify-content: center;
}
.footer-bottom > div {
    width: 170px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    border: 2px solid #0abbb9;
    border-radius: 4px;
    background-color: #0abbb9;
    margin: 50px 20px;
}
.footer-bottom > div:first-of-type {
    color: #0abbb9;
    background-color: transparent;
}

.pd-r {
    padding-right: 35px;
}
.pd-r2 {
    padding-right: 20px;
}
.disabled {
    color: #999;
}
.block {
    background-color: #fff;
    padding: 30px;
    padding-bottom: 0;
    margin-bottom: 16px;
}
.block .title {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
.block .title .iconfont {
    font-size: 40px;
    color: #0abbb9;
}
.block .cell {
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}
.block .cell input {
    border: 0;
}
.block .cell:last-of-type {
    border: 0;
}
.block .cell .left {
    width: 180px;
}
.block .cell .left span {
    color: red;
}
.block .cell .right {
    width: 80%;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.right input {
    width: 100%;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.err {
    text-align: center;
    padding-top: 200px;
}
.err_text {
    font-size: 30px;
    margin-top: 10px;
    padding: 0 30px;
}
/* 弹出框 */
.popup {
    width: 85%;
    padding: 40px 40px 20px;
    border-radius: 10px;
    font-size: 28px;
}
.popup p {
    margin: 10px;
    font-size: 28px;
}
.popup .bottom {
    display: flex;
    margin-top: 20px;
    text-align: center;
}
.popup .bottom div:nth-of-type(1) {
    border-right: 2px solid #eee;
    color: #999;
}
.popup .bottom div {
    width: 50%;
    color: #0abbb9;
    margin: 10px;
}
.sms_box {
    margin: 10px;
}
.van-button--info {
    background-color: #0abbb9;
    border-color: #0abbb9;
}
</style>